/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './white-paper.scss';

const WhitePaper = ({
  pageContext: { metaDescription, metaTitle, frontmatter, html }
}) => (
  <Layout>
    <SEO title={frontmatter.title} description={metaDescription} />
    <section className="container white-paper--section">
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        style={{ maxWidth: '640px' }}
      />
    </section>
  </Layout>
);

export default WhitePaper;
